<template>
  <div class="home">
    <div class="home__banner"></div>
    <section class="home__poster">
      <img
        src="/assets/img/home/ourGroup.jpg"
        alt="Our Group"
        class="home__poster__img"
      />
    </section>
    <section class="home__description">
      <h1 class="home__description__header text-h3">
        Konstantin Bukhryakov Group
      </h1>
      <p class="home__description__text text-subtitle-1">
        Our research group uses organometallic chemistry to create new catalytic
        systems. Our group is always looking for talented and motivated
        undergraduate and graduate students!
      </p>
      <a
        class="home__description__button"
        href="https://twitter.com/BukhGroup"
        target="_blank"
      >
        <v-btn color="primary">
          Our Twitter
          <img
            src="/assets/img/home/twitter.svg"
            alt="twitter"
            class="home__description__button__icon"
          />
        </v-btn>
      </a>
    </section>
    <section class="home__about-kostya">
      <div class="home__about-kostya__wrapper">
        <img
          src="/assets/img/home/KB.jpg"
          alt="Konstantin Bukhryakov"
          class="home__about-kostya__photo"
        />
        <div class="home__about-kostya__description">
          <h2 class="home__about-kostya__description__title text-h5">
            Konstantin Bukhryakov
          </h2>
          <p class="home__about-kostya__description__text text-subtitle-1">
            Kostya, a native of Tyumen, Russia, excelled in organic chemistry,
            earning his Ph.D. at Lomonosov Moscow State University and
            contributing to the synthesis of enantiopure organic compounds for
            major pharmaceuticals. His postdoctoral work at MIT under Nobel
            laureate Richard Schrock led to breakthroughs in molybdenum complex
            chemistry. In 2018, he joined Florida International University as an
            Assistant Professor in Organic Chemistry.
          </p>
          <v-btn
            color="primary"
            class="home__about-kostya__description__button"
            @click="goGroup"
          >
            Learn More
          </v-btn>
        </div>
      </div>
    </section>
    <section class="home__work-funded">
      <h2 class="home__work-funded__title text-h5">Our work is funded by</h2>
      <img
        src="/assets/img/home/work-funded.png"
        alt="work funded"
        class="home__work-funded__img"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import router from '@/router';

const goGroup = () => {
  router.push('/group');
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
</script>

<style scoped lang="scss">
.home {
  &__banner {
    display: none;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 25%, $primary-light),
      url('/assets/img/home/banner.png');
    background-size: cover;
    height: 90px;
    filter: blur(1px);
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  &__poster {
    width: 100%;
    text-align: center;
    position: relative;
    background-image: url('/assets/img/home/background-our-group.jpg');
    background-size: cover;
    background-position: center;
    background-color: rgba(235, 255, 240, 0.7);
    background-blend-mode: lighten;
    line-height: 0;

    &__img {
      max-width: 800px;
      width: 100%;
    }
  }

  &__description {
    max-width: 1200px;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    &__header {
      display: inline-block;
      color: $primary-dark;
      padding: 20px 40px;
      margin-top: 40px;
      border-radius: 20px;
    }

    &__text {
      display: inline-block;
      max-width: 600px;
    }

    &__button {
      margin: 25px auto;
      display: block;
      &__icon {
        width: 26px;
        margin-left: 8px;
      }
    }
  }

  &__about-kostya {
    width: 100%;
    padding: 60px;
    background-color: $primary-light;
    display: flex;
    justify-content: center;

    &__wrapper {
      display: flex;
    }

    &__photo {
      display: block;
      border-radius: 20px;
      max-height: 450px;
    }

    &__description {
      padding: 0 40px;
      &__title {
        margin: 20px 0;
      }
      &__text {
        text-align: justify;
        max-width: 400px;
      }
      &__button {
        margin: 20px 0 0 20px;
      }
    }
  }

  &__work-funded {
    margin: 60px 0;

    &__title {
      text-align: center;
    }

    &__img {
      max-width: 1000px;
      width: 100%;
    }
  }
}

@media (max-width: 755px) {
  .home__about-kostya {
    padding: 60px 32px;

    &__description {
      padding-right: 0;
    }
  }
}

@media (max-width: 620px) {
  .home__about-kostya__photo {
    max-height: 400px;
  }

  .home__description__text {
    padding: 0 16px;
  }
}

@media (max-width: 600px) {
  .home__banner {
    display: block;
  }

  .home__description__header {
    font-size: 24px !important;
    margin-top: 20px;
  }

  .home__about-kostya__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .home__about-kostya__description {
    width: 100%;
    padding-left: 0;
  }
}
</style>
