<template>
  <div class="publications">
    <div class="publications__banner"></div>
    <ul class="publications__list">
      <li
        v-for="(publication, index) in publications"
        :key="index"
        class="publications__list__item"
      >
        <h3
          class="publications__list__item__title text-h5"
          v-if="index === 0 || index === 10"
        >
          {{ index === 0 ? 'FIU' : index === 10 ? 'Before FIU' : '' }}
        </h3>
        <h4
          :class="{
            'publications__list__item__title text-h6': true,
            'double-title': index === 10,
          }"
          v-if="index === 10 || index === 27"
        >
          {{
            index === 10
              ? 'Postdoctoral research (MIT)'
              : index === 27
              ? 'Ph.D. research (MSU and CDI)'
              : ''
          }}
        </h4>
        <div class="publications__list__item__index text-subtitle-1">
          <p>{{ publications.length - index }}</p>
        </div>
        <div class="publications__list__item__wrapper">
          <img
            :src="publication.img"
            alt="publication image"
            v-if="publication.img"
            class="publications__list__item__img"
            @click="openDialog(publication.img, publication.content)"
          />
          <div
            :class="{
              'publications__list__item__content text-subtitle-1': true,
              'without-img': !publication.img,
            }"
          >
            <div>
              {{ publication.content + ' ' }}
              <p class="mt-2">
                <a :href="publication.doiLink" target="_blank">{{
                  publication.doi
                }}</a>
                <span
                  v-html="
                    publication.addInformation
                      ? ' ' + publication.addInformation
                      : ''
                  "
                ></span>
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <v-dialog v-model="dialog">
      <v-card class="publications__dialog">
        <div class="publications__dialog__img-wrapper">
          <img
            :src="selectedImage"
            alt="publication image"
            class="publications__dialog__img mt-8"
          />
        </div>
        <p class="publications__dialog__text my-4">{{ selectedText }}</p>
        <v-btn
          class="publications__dialog__button mb-6"
          variant="elevated"
          color="primary"
          @click="dialog = false"
          >Закрыть</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import publications from '@/data/publications.json';
import { ref } from 'vue';

const dialog = ref(false);
const selectedImage = ref('');
const selectedText = ref('');

function openDialog(imgSrc: string, text: string) {
  selectedImage.value = imgSrc;
  selectedText.value = text;
  dialog.value = true;
}
</script>

<style scoped lang="scss">
.publications {
  margin-bottom: 60px;
  &__banner {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 75%, white),
      url('/assets/img/publications/banner.png');
    background-size: cover;
    height: 90px;
    filter: blur(1px);
  }

  &__list {
    list-style: none;
    margin: auto;
    padding: 16px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 20px 0;

      &__title {
        width: 100%;
        text-align: center;
        margin: 20px 0;
        color: $primary-dark;

        &.double-title {
          margin-top: 0;
        }
      }

      &__index {
        content: '';
        position: relative;
        color: $white;
        width: 30px;
        height: 30px;
        background-color: $primary;
        border-radius: 50%;

        & p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          line-height: 1.5rem;
        }
      }

      &__wrapper {
        max-width: 1000px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-left: 20px;
        border: 1px solid $primary-grey;
        border-radius: 20px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        padding: 16px 24px;
      }

      &__img {
        cursor: pointer;
        margin: 32px 16px;
        max-width: calc(50% - 24px);
        max-height: 250px;
      }

      &__content {
        width: calc(50% - 24px);

        &.without-img {
          width: 100%;
        }
      }
    }
  }

  &__dialog {
    text-align: center;
    width: auto;

    &__img {
      max-height: 60vh;
      width: auto;
      height: auto;
    }

    &__text {
      display: block;
      margin: auto;
      max-width: 500px;
    }

    &__button {
      display: block;
      margin: auto;
      width: 150px;
    }
  }
}
</style>
