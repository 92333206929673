<template>
  <div class="group">
    <div class="group__banner"></div>
    <div class="group__person">
      <div class="group__person__wrapper">
        <img
          src="/assets/img/group/KB.jpg"
          alt="Konstantin Bukhryakov"
          class="group__photo"
        />
        <div>
          <h3 class="group__person__title text-h5">Konstantin Bukhryakov</h3>
          <h3 class="group__person__subtitle text-h6">Education</h3>
          <ul class="group__person__list text-subtitle-1">
            <li class="group__person__list__item">
              Postdoc, MIT, USA, 2015−2018 (Richard Schrock)
            </li>
            <li class="group__person__list__item">
              Postdoc, KAUST, Saudi Arabia, 2012−2015 (Valentin Rodionov)
            </li>
            <li class="group__person__list__item">
              Ph.D., Lomonosov Moscow State University, Russia, 2008−2012
              (Alexander Kurkin)
            </li>
            <li class="group__person__list__item">
              B.S., Tyumen State University, Russia, 2001−2006
            </li>
          </ul>
          <div class="group__person__cv-wrapper text-subtitle-1">
            <v-btn
              icon="mdi-download"
              color="primary"
              density="comfortable"
              href="/CV_Konstantin-Bukhryakov-2.pdf"
              download="CV_Konstantin_Bukhryakov.pdf"
              class="mr-4"
            ></v-btn>
            <a href="/CV_Konstantin-Bukhryakov-2.pdf" target="_blank"
              >CV Konstantin Bukhryakov</a
            >
          </div>
        </div>
      </div>
      <h3 class="group__person__subtitle text-h6">
        About Konstantin (Kostya) Bukhryakov (pronounced BUK-RI-KOV)
      </h3>
      <p class="group__person__text text-subtitle-1">
        Kostya was born in
        <a href="https://en.wikipedia.org/wiki/Tyumen" target="_blank">Tyumen</a
        >, West Siberia, Russia, in 1984. He obtained his B.S. degree in 2006
        from Tyumen State University.
      </p>
      <p class="group__person__text text-subtitle-1">
        After graduation, he moved to Moscow, Russia, where he held a position
        as a Research Scientist at the Chemical Diversity Research Institute
        (CDI, a subsidiary of
        <a
          href="https://www.chemdiv.com/catalog/complete-list-of-compounds-libraries/?https://www.chemdiv.com/&gclid=EAIaIQobChMIgM-xvpbr9AIVeopaBR1uwwpNEAAYASAAEgK73PD_BwE"
          target="_blank"
          >ChemDiv</a
        >, a contract research organization with headquarters in San Diego, CA).
        He worked on projects for Novartis, Merck, Abbott, Eli Lilly, and
        others, developing essential synthetic strategies towards the clients’
        target compounds, including the synthesis of libraries of enantiopure
        organic compounds.
      </p>
      <p class="group__person__text text-subtitle-1">
        Kostya joined the graduate program at the Lomonosov Moscow State
        University in 2008. He was working under Professor Alexander Kurkin and
        Professor Marina Yurovskaya on biologically active heterocyclic
        compounds and obtained his Ph.D. degree in 2012.
      </p>
      <p class="group__person__text text-subtitle-1">
        Shortly after receiving a Ph.D. degree, he was invited to join the
        research group of Professor
        <a href="https://rodionovlab.org/" target="_blank">Valentin Rodionov</a>
        (currently at Case Western Reserve University, Cleveland, OH) at the
        King Abdullah University of Science and Technology (KAUST), Saudi
        Arabia, as a Postdoctoral Fellow. At KAUST, he developed new catalysts
        based on amphiphilic polymers, including organo- and metal complex
        catalysts.
      </p>
      <p class="group__person__text text-subtitle-1">
        In 2015, Kostya joined the research group of Professor
        <a href="https://schrockgroup.mit.edu/" target="_blank"
          >Richard Schrock</a
        >
        (Nobel Prize 2005) at the Massachusetts Institute of Technology (MIT) as
        a Postdoctoral Associate. During his career at MIT, he developed a class
        of molybdenum complexes that have shown unprecedented activity for the
        cross-metathesis of electron-deficient olefins (halogen and
        trifluoromethyl substituted alkenes) with unreactive substrates
        (polysubstituted olefins). Furthermore, he established a synthetic route
        to molybdenum oxo alkylidene complexes, an elusive class of compounds
        for 35 years, proposed in classical olefin metathesis systems.
      </p>
      <p class="group__person__text text-subtitle-1">
        He joined the Department of Chemistry and Biochemistry at Florida
        International University in 2018 as an Assistant Professor in Organic
        chemistry.
      </p>
      <h3 class="group__person__title group__person__title--post text-h4">
        Postdoc
      </h3>
      <div class="group__person__wrapper">
        <img
          src="/assets/img/group/madhu-reddy.jpg"
          alt="Madhu Reddy"
          class="group__photo"
        />
        <div>
          <h3 class="group__person__title text-h5">
            Madhu Reddy | <a href="mailto:mgangire@fiu.edu">mgangire@fiu.edu</a>
          </h3>
          <p
            class="group__person__text group__person__text--assistant text-subtitle-1"
          >
            Madhu originally hails from Andhra Pradesh, India. He received his
            bachelor’s from S.V. University and master’s from S.K. University.
            He chose his scientific career as a doctoral research fellow in Dr.
            Rambabu Gundla’s group at GITAM university, where his research
            interest revolved around developing the novel imidazopyridine,
            pyrimidine, and spiro derivatives as new anti-cancer agents. He
            associated with Jubilant Chemsys (India) and Curia global (India &
            USA) industries. In May 2023, Madhu joined the BukhGroup as a
            post-doctoral research associate. He is enthusiastic about
            developing organic materials, including polymers. He enjoys taking
            walks and watching movies, and T.V. shows in his spare time.
          </p>
        </div>
      </div>
      <h3 class="group__person__title group__person__title--post text-h4">
        Graduate Students
      </h3>
      <div class="group__person__wrapper">
        <img
          src="/assets/img/group/shirley-hernandez.jpg"
          alt="Shirley Hernandez"
          class="group__photo"
        />
        <div>
          <h3 class="group__person__title text-h5">
            Shirley Hernandez |
            <a href="mailto:shern230@fiu.edu">shern230@fiu.edu</a> | 4th year
          </h3>
          <p
            class="group__person__text group__person__text--assistant text-subtitle-1"
          >
            Shirley was born in Havana Cuba and immigrated to the United States,
            specifically New Jersey, with my immediate family back in 2000. I
            later moved to Florida in 2005 and became interested in organic
            chemistry when I entered high school at Jose Marti MAST 6-12
            Academy. I went to FIU to get my Bachelor’s where I worked with one
            of Dr. Lima’s postdocs to examine the structural complexity of the
            ubiquitin C-terminal hydrolase (UCH) knot protein using Trapped Ion
            Mobility Spectroscopy-Mass Spectrometry (TIMS MS) with the goal of
            learning and using analytical tools and strategies to characterize
            biomolecules. After working with them for a year and graduating in
            Fall 2019, I returned to FIU once more to pursue my Ph.D. degree,
            this time working under Dr. Bukhryakov’s group. Currently, I am in
            the process of developing new transition metal catalysts for use in
            olefin metathesis. In my spare time, I enjoy taking short walks,
            watching movies and TV shows, and reading comics.
          </p>
        </div>
      </div>
      <div class="group__person__wrapper">
        <img
          src="/assets/img/group/carlos-acosta.jpg"
          alt="Carlos Acosta"
          class="group__photo"
        />
        <div>
          <h3 class="group__person__title text-h5">
            Carlos Acosta |
            <a href="mailto:cacos046@fiu.edu">cacos046@fiu.edu</a> | 3rd year
          </h3>
          <p
            class="group__person__text group__person__text--assistant text-subtitle-1"
          >
            Carlos was a born and raised native Floridian who began his higher
            education at Miami Dade College. He eventually found himself at FIU
            and joined a research group under Dr. Konstantin Bukhryakov. Carlos
            plans to obtain his Ph.D. in chemistry once he completes his
            bachelor’s degree and use his knowledge to synthesize new
            antibiotics to combat antibiotic-resistant bacteria. While not
            studying, Carlos enjoys reading, playing basketball, and long walks
            on the beach.
          </p>
        </div>
      </div>
      <div class="group__person__wrapper">
        <img
          src="/assets/img/group/vasilisa-krivovicheva.jpg"
          alt="Vasilisa Krivovicheva"
          class="group__photo"
        />
        <div>
          <h3 class="group__person__title text-h5">
            Vasilisa Krivovicheva |
            <a href="mailto:vkriv001@fiu.edu">vkriv001@fiu.edu</a> | 1rd year
          </h3>
          <p
            class="group__person__text group__person__text--assistant text-subtitle-1"
          >
            Originally born in South Bend (Indiana), Vasya grew up in Saint
            Petersburg, Russia. She earned her bachelor’s and master’s degrees
            at Saint Petersburg State University. During her master’s, she
            worked on various medicinal chemistry and synthetic projects as part
            of Prof. Krasavin’s research group. In August 2023, she relocated to
            Florida to join Prof. Bukhryakov’s team and explore the world of
            organometallic chemistry in general and olefin metathesis in
            particular. She is enthusiastic about conducting meaningful
            research. In her free time, Vasya enjoys activities like reading,
            hiking, and learning new things, such as playing guitar and
            painting.
          </p>
          <div class="group__person__links-wrapper">
            <a
              :href="`https://twitter.com/vasyananan`"
              target="_blank"
              class="group__person__link text-subtitle-1"
            >
              <img
                class="group__person__link-icon mr-2"
                src="/assets/img/footer/twitter.svg"
                alt="twitter"
              />
              <p>@vasyananan</p></a
            >
            <a
              :href="`https://www.researchgate.net/profile/Vasilisa-Krivovicheva`"
              target="_blank"
              class="group__person__link text-subtitle-1"
            >
              <img
                class="group__person__link-icon mr-2"
                src="/assets/img/footer/link.svg"
                alt="ResearchGate"
              />
              <p>ResearchGate</p></a
            >
          </div>
        </div>
      </div>
      <div class="group__person__wrapper">
        <img
          src="/assets/img/group/samuel-aguero.jpg"
          alt="Samuel Aguero"
          class="group__photo"
        />
        <div>
          <h3 class="group__person__title text-h5">
            Samuel Aguero |
            <a href="mailto:sague005@fiu.edu">sague005@fiu.edu</a> | 1rd year
          </h3>
          <p
            class="group__person__text group__person__text--assistant text-subtitle-1"
          >
            Samuel is a native born and raised in South Florida. Immediately
            after high school he began and completed his bachelor’s degree at
            FIU (2023). In his final semester of undergraduate schooling, he
            wanted exposure to scientific research using organic materials for
            synthesis and joined the Dr. Konstantin Bukhryakov research group.
            Eventually choosing to stay to pursue a Ph.D. in Chemistry by
            investigating organometallic chemistry and olefin metathesis. Samuel
            enjoys playing competitive games and running in his free time.
          </p>
        </div>
      </div>
      <div class="group__undergraduate">
        <h3 class="group__person__title text-h4">Undergraduate Students</h3>
        <ul class="group__undergraduate__list">
          <li class="group__undergraduate__list__item">
            <p class="group__undergraduate__list__item__text">
              Jonathan Chinique
            </p>
            <a
              href="mailto:jchin038@fiu.edu"
              class="group__undergraduate__list__item__text group__undergraduate__list__item__text--link"
              >jchin038@fiu.edu</a
            >
          </li>
          <li class="group__undergraduate__list__item">
            <p class="group__undergraduate__list__item__text">Celine Gomez</p>
            <a
              href="mailto:cgome191@fiu.edu"
              class="group__undergraduate__list__item__text group__undergraduate__list__item__text--link"
              >cgome191@fiu.edu</a
            >
          </li>
          <li class="group__undergraduate__list__item">
            <p class="group__undergraduate__list__item__text">Rachel Perez</p>
            <a
              href="mailto:rpere453@fiu.edu"
              class="group__undergraduate__list__item__text group__undergraduate__list__item__text--link"
              >rpere453@fiu.edu</a
            >
          </li>
          <li class="group__undergraduate__list__item">
            <p class="group__undergraduate__list__item__text">Andy Lamur</p>
            <a
              href="mailto:alamu002@fiu.edu"
              class="group__undergraduate__list__item__text group__undergraduate__list__item__text--link"
              >alamu002@fiu.edu</a
            >
          </li>
          <li class="group__undergraduate__list__item">
            <p class="group__undergraduate__list__item__text">Luis Lara</p>
            <a
              href="mailto:llara020@fiu.edu"
              class="group__undergraduate__list__item__text group__undergraduate__list__item__text--link"
              >llara020@fiu.edu</a
            >
          </li>
        </ul>
      </div>
      <h3 class="group__alumni text-h4">Alumni</h3>
      <div class="group__wrapper">
        <h3 class="group__alumni__title text-h5 mt-6 mb-4">Graduate Students</h3>
        <div class="group__alumni__wrapper text-subtitle-1">
          <div>
            <p>Dr. Gabriela Tejeda (2018-2023)</p>
            <p class="text-caption">MS&T Specialist II at Teva Pharmaceuticals</p>
          </div>
        </div>
      </div>
      <div class="group__wrapper">
        <h3 class="group__alumni__title text-h5 mt-6 mb-4">Postdocs</h3>
        <div class="group__alumni__wrapper text-subtitle-1">
          <div class="group__alumni__item">
            <p>Dr. Dmitry Belov (2019-2022)</p>
            <p class="text-caption">Scientist I at Sanegene Bio USA, Inc</p>
          </div>
          <div class="group__alumni__item">
            <p>Dr. Victor Desyatkin (2020-2021)</p>
            <p class="text-caption">Postdoctoral Fellow at Case Western Reserve University</p>
          </div>
        </div>
      </div>
      <div class="group__wrapper">
        <h3 class="group__alumni__title text-h5 mt-6 mb-4">Master students</h3>
        <div class="group__alumni__wrapper text-subtitle-1">
          <p>Sergei Chuprin (2019-2021)</p>
        </div>
      </div>
      <div class="group__wrapper">
        <h3 class="group__alumni__title text-h5 mt-6 mb-4">Undergraduate students</h3>
        <div class="group__alumni__wrapper text-subtitle-1">
          <p class="group__alumni__item">Ashleigh Sherman (2023)</p>
          <p class="group__alumni__item">Rene Perez (2023)</p>
          <p class="group__alumni__item">Carlos Escobar (2022)</p>
          <p class="group__alumni__item">Eber Mendez (2022)</p>
          <p class="group__alumni__item">Gabriel Moljo (2021)</p>
          <p class="group__alumni__item">Bryson Orosa (2020)</p>
          <p class="group__alumni__item">Alejandro Palmero (2019)</p>
        </div>
      </div>
      <div class="group__wrapper">
        <h3 class="group__alumni__title text-h5 mt-6 mb-4">
          Summer Internship Program for high school students
        </h3>
        <div class="group__alumni__wrapper text-subtitle-1">
          <p class="group__alumni__item">Christian Feliciello (2023)</p>
          <p class="group__alumni__item">Faith Rubi (2023)</p>
          <p class="group__alumni__item">Jayden Sacasa (2022)</p>
          <p class="group__alumni__item">Nicole Mederos (2021)</p>
          <p class="group__alumni__item">Ana Pereira (2019)</p>
          <p class="group__alumni__item">Aaliyah Fuller (2019)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.group {
  margin-bottom: 60px;
  &__banner {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 75%, white),
      url('/assets/img/group/banner.png');
    background-size: cover;
    height: 90px;
    filter: blur(1px);
  }

  &__person {
    max-width: 1000px;
    width: 100%;
    margin: auto;

    &__wrapper {
      margin: 40px 0 20px;
      display: flex;
      justify-content: flex-start;
      gap: 40px;
    }

    &__title {
      margin-bottom: 16px;
      color: $primary-dark;

      &--post {
        text-align: center;
      }
    }

    &__subtitle {
      margin-bottom: 8px;
      color: $primary-dark;
    }

    &__list {
      margin-bottom: 16px;
    }

    &__text {
      margin-bottom: 8px;
      text-align: justify;
    }

    &__links-wrapper {
      display: flex;
      gap: 20px;
    }

    &__link {
      display: flex;
    }

    &__link-icon {
      width: 24px;
    }
  }

  &__photo {
    width: 230px;
    height: 230px;
    border-radius: 20px;
  }

  &__undergraduate {
    margin-top: 40px;
    text-align: center;

    &__list {
      list-style: none;

      &__item {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-bottom: 8px;

        &__text {
          width: 50%;
          text-align: end;

          &--link {
            text-align: start;
          }
        }
      }
    }
  }

  &__alumni {
    text-align: center;
    color: $primary-dark;
    margin: 40px 0 20px;

    &__wrapper {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    &__title {
      margin: 8px 0;
      text-align: center;
      color: $primary-dark;
    }

    &__item {
      width: 45%;
      text-align: end;
      margin-bottom: 8px;

      &:last-child {
        text-align: center;
      }

      &:nth-child(even) {
        text-align: start;
        margin-left: 20px;
      }
    }
  }
}
</style>
