<template>
  <v-fade-transition :hide-on-leave="true">
    <v-btn
      class="scroll-up"
      icon=""
      @click="scrollUp"
      density="comfortable"
      v-if="isButtonScroll && !useRoute().fullPath.includes('chat')"
      ><v-icon color="primary">mdi-chevron-up</v-icon></v-btn
    >
  </v-fade-transition>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ref, onMounted, onBeforeUnmount } from 'vue';

const isButtonScroll = ref(false);
const checkScroll = () => {
  const scrollFromTop =
    window.pageYOffset || document.documentElement.scrollTop;
  const windowHeight = window.innerHeight;

  isButtonScroll.value = scrollFromTop > windowHeight * (2 / 3);
};
const scrollUp = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => window.addEventListener('scroll', checkScroll));
onBeforeUnmount(() => window.removeEventListener('scroll', checkScroll));
</script>

<style lang="scss">
.scroll-up {
  position: fixed;
  bottom: 90px;
  right: 90px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  color: $primary;
}

@media (max-width: 755px) {
  .scroll-up {
    bottom: 45px;
    right: 45px;
  }
}
</style>
