import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import ResearchPage from '@/views/ResearchPage.vue';
import PublicationsPage from '@/views/PublicationsPage.vue';
import GroupPage from '@/views/GroupPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'main',
    path: '/',
    redirect: '/home',
  },
  {
    name: 'home',
    path: '/home',
    component: HomePage,
  },
  {
    name: 'research',
    path: '/research',
    component: ResearchPage,
  },
  {
    name: 'publications',
    path: '/publications',
    component: PublicationsPage,
  },
  {
    name: 'group',
    path: '/group',
    component: GroupPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
