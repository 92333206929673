<template>
  <v-tabs v-model="menuItem" class="menu" color="primary">
    <div class="divider"></div>
    <v-tab
      exact
      value="home"
      :key="1"
      class="menu__item text-subtitle-1"
      @click="handlePage('home')"
    >
      Home</v-tab
    >
    <v-tab
      exact
      value="research"
      :key="2"
      class="menu__item text-subtitle-1"
      @click="handlePage('research')"
    >
      Research</v-tab
    >
    <div class="menu__spacer"></div>
    <v-tab
      exact
      value="publications"
      :key="5"
      class="menu__item text-subtitle-1"
      @click="handlePage('publications')"
    >
      Publications</v-tab
    >
    <v-tab
      exact
      value="group"
      :key="3"
      class="menu__item text-subtitle-1"
      @click="handlePage('group')"
    >
      Group</v-tab
    >
    <v-btn class="menu__btn my-1 ml-2" color="primary" @click="scrollDown"
      >Contacts</v-btn
    >
  </v-tabs>
</template>

<script setup lang="ts">
import handlePage from '@/utils/handlePage';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const menuItem = computed(() => useRoute().fullPath.replace('/', ''));
const scrollDown = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });
};
</script>

<style lang="scss">
.menu {
  &__spacer {
    content: '';
    width: 150px;
  }
}

.divider {
  content: '';
  width: 118px;
}

@media (max-width: 905px) {
  .menu {
    display: none !important;
  }
}
</style>
