<template>
  <footer class="desktop-footer">
    <div class="desktop-footer__wrapper">
      <div class="desktop-footer__content-column">
        <h5 class="desktop-footer__content__title text-caption mb-2">
          Dr. Konstantin Bukhryakov
        </h5>
        <div class="desktop-footer__requisites text-subtitle-2">
          <p class="mb-1">Assistant Professor</p>
          <p class="mb-1">Department of Chemistry and Biochemistry</p>
          <p class="mb-1">Florida International University</p>
          <p class="mb-1">11200 SW 8th St., CP 317</p>
          <p>Miami, FL 33199</p>
        </div>
      </div>
      <div
        class="desktop-footer__content-column desktop-footer__content-column--right"
      >
        <div class="desktop-footer__content-column-wrapper">
          <h5 class="desktop-footer__content__title text-caption mb-2">
            Contacts
          </h5>
          <div class="desktop-footer__content__link-wrapper">
            <a
              :href="`mailto:kbukhrya@fiu.edu`"
              target="_blank"
              class="desktop-footer__content__link text-subtitle-2"
            >
              <img
                class="desktop-footer__content__link-icon mr-2"
                src="/assets/img/footer/mail.svg"
                alt="mail"
              />
              <p>kbukhrya@fiu.edu</p></a
            >
            <a
              :href="`tel:305-348-6307`"
              target="_blank"
              class="desktop-footer__content__link text-subtitle-2"
            >
              <img
                class="desktop-footer__content__link-icon mr-2"
                src="/assets/img/footer/phone-call.svg"
                alt="mail"
              />
              <p>305-348-6307</p></a
            >
            <a
              :href="`https://twitter.com/BukhGroup`"
              target="_blank"
              class="desktop-footer__content__link text-subtitle-2"
            >
              <img
                class="desktop-footer__content__link-icon mr-2"
                src="/assets/img/footer/twitter.svg"
                alt="twitter"
              />
              <p>@BukhGroup</p></a
            >
            <a
              :href="`https://case.fiu.edu/about/directory/people/konstantin-bukhryakov.html`"
              target="_blank"
              class="desktop-footer__content__link desktop-footer__content__link--college text-subtitle-2 mt-2"
            >
              <p>@College of Arts, Education and Sciences at FIU</p></a
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.desktop-footer {
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  background-color: $primary-light;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;
    padding: 0 16px;
  }

  &__content {
    &__title {
      font-weight: normal;
      opacity: 80%;
    }

    &__link-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__link-icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }

    &__link {
      display: flex;
      align-items: center;
      color: $primary;
      text-decoration: none;

      &--college {
        text-decoration: underline;
      }
    }
  }

  &__content-column--right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: 425px) {
  .desktop-footer__wrapper {
    flex-direction: column;
  }

  .desktop-footer__content__title {
    margin-top: 32px;
  }
}
</style>
