<template>
  <div class="research">
    <div class="research__banner"></div>
    <div class="research__wrapper">
      <h2 class="research__header text-h3">Innovations in Olefin Metathesis</h2>
      <p class="research__text text-subtitle-1">
        Olefin metathesis is a vital reaction in research and industrial
        applications that utilizes heterogeneous and homogeneous transition
        metal catalysts. Starting as a mysterious reaction where polymerization
        of norbornene catalyzed by ill-defined Ti complexes that led to an
        unsaturated polymer seven decades ago, it is now a well-understood
        chemical transformation based on a robust mechanism involving transition
        metal carbene complexes. Essential well-defined homogeneous catalysts
        for olefin metathesis are based on Ru, Mo, and W alkylidene complexes.
        Those catalysts are used in the synthesis of polymers, petrochemicals,
        agrochemicals, pharmaceutical compounds, and natural products. The
        tremendous success of the olefin metathesis reaction was possible due to
        the development of reliable methods of synthesis transition metal
        carbene complexes in the last few decades. Our projects focus on the
        synthesis of V, Fe, Mo, and W carbene complexes and their application
        for olefin metathesis and other catalytic transformations.
      </p>
    </div>
    <div class="research__wrapper my-12">
      <img src="/assets/img/research/v.png" alt="V" />
      <h3 class="research__header research__header--h3 text-h5">
        Vanadium in Olefin Metathesis
      </h3>
      <p class="research__text text-subtitle-1">
        High-oxidation-state V alkylidene complexes have been extensively
        explored for the ring-opening metathesis polymerization of various
        cyclic alkenes in the last decade. However, the examples of V catalyzed
        olefin metathesis of acyclic olefins such as ring-closing metathesis and
        cross-metathesis are limited due to the lack of a current method to vary
        a ligand set around V. This project focuses on the development of the
        alternative approach for the synthesis of V alkylidenes and the
        systematical study of the influence of size and the electronic
        characteristics of the ligand set around the metal on olefin metathesis.
      </p>
    </div>
    <div class="research__wrapper my-12">
      <img src="/assets/img/research/Fe.png" alt="Fe" />
      <h3 class="research__header research__header--h3 text-h5">
        Iron as a Pioneering Catalyst
      </h3>
      <p class="research__text text-subtitle-1">
        Our project focuses on the synthesis, characterization, and development
        of the Fe alkylidene catalysts for olefin metathesis. Despite the
        extensive theoretical and experimental studies to the best of our
        knowledge, there are no successful examples of Fe-catalyzed olefin
        metathesis. The long-standing goal of this project is to not only
        complement existing processes but to discover a new type of reactivity
        of Fe alkylidene complexes considering the unique electronic structure
        of the first-row metals.
      </p>
    </div>
    <div class="research__wrapper my-12">
      <img src="/assets/img/research/Mo-W.png" alt="Mo-W" />
      <h3 class="research__header research__header--h3 text-h5">
        Molybdenum and Tungsten in Modern Catalysis
      </h3>
      <p class="research__text text-subtitle-1">
        Nowadays, the vast majority of Mo/W alkylidene catalysts for olefin
        metathesis are prepared using the “classical” method based on the
        alpha-hydrogen abstraction reaction of Mo dialkyls. The second approach
        is based on the protonation of alkylidynes. Recently we discovered a
        system that produces catalytically active Mo carbene complexes from Mo
        benzylidyne in the presence of olefins in situ. This project focuses on
        elucidating the alkylidene formation mechanism, which does not include
        alpha-hydrogen abstraction or alkylidyne protonation.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.research {
  margin-bottom: 60px;

  &__banner {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 75%, white),
      url('/assets/img/research/banner.png');
    background-size: cover;
    height: 90px;
    filter: blur(1px);
  }

  &__wrapper {
    max-width: 1000px;
    width: 100%;
    margin: auto;
  }

  &__header {
    margin: 40px 0 20px;
    color: $primary-dark;

    &--h3 {
      margin: 8px 0;
    }
  }

  &__text {
    text-align: justify;
  }
}
</style>
