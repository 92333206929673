import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router';
import { createPinia } from 'pinia';
import vuetify from '@/plugins/vuetify';
import 'vuetify/styles';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(vuetify);
app.mount('#app');
