<template>
  <v-menu class="burger-menu" @update:modelValue="handleMenuUpdate">
    <template v-slot:activator="{ props }">
      <v-btn
        class="burger-menu__btn"
        variant="text"
        icon="mdi-menu"
        v-bind="props"
      ></v-btn>
    </template>
    <v-list class="burger-menu__list py-0">
      <v-list-item
        v-for="(item, i) in menuItems"
        :key="i"
        :class="{
          'burger-menu__item px-12': true,
          active: useRoute().fullPath === item.path,
        }"
        @click="navigate(item.path)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import router from '@/router';

const menu = ref(false);
const menuItems = [
  { title: 'Home', path: '/home' },
  { title: 'Research', path: '/research' },
  { title: 'Publications', path: '/publications' },
  { title: 'Group', path: '/group' },
  { title: 'Contacts', path: 'contacts' },
];
const handleMenuUpdate = () => {
  menu.value = !menu.value;
};
const navigate = (path: string) => {
  if (path === 'contacts') {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  } else {
    router.push(path);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};
</script>

<style lang="scss">
.burger-menu {
  display: none !important;

  &__btn {
    display: none !important;
  }

  &__item {
    text-align: center;

    &.active {
      background-color: $primary-light;
    }
  }
}

@media (max-width: 905px) {
  .burger-menu {
    display: block !important;

    &__btn {
      margin: 0 20px 0 auto;
      display: block !important;
    }
  }
}
</style>
