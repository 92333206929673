<template>
  <header density="compact" class="header">
    <img
      src="/assets/img/logo.png"
      alt="Logo Bukh Group"
      class="header__logo"
      @click="goHome"
    />
    <div>
      <TheDesktopMenu />
      <TheBurgerMenu />
    </div>
  </header>
</template>

<script setup lang="ts">
import TheDesktopMenu from '@/components/common/header/TheDesktopMenu.vue';
import TheBurgerMenu from '@/components/common/header/TheBurgerMenu.vue';
import router from '@/router';

const goHome = () => {
  router.push('/home');
};
</script>

<style lang="scss">
.header {
  position: relative;
  background-color: $primary-light;

  &__logo {
    cursor: pointer;
    position: absolute;
    width: 110px;
    height: 110px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  }
}
</style>
